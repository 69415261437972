<template>
  <div
    v-show="
      (installationsSatisfactionForm > 0 && can('seeInstallationSurveys')) ||
      (installationsSatisfactionForm > 0 && can('seeInstallationSurveys')) ||
      (supportSatisfactionForm > 0 && can('seeAssistSurveys'))
    "
  >
    <Message severity="warn">
      <span
        class="p-d-flex p-ai-center"
        v-if="numberOfBookings > 0 && can('seeBookinglistMenu')"
      >
        Existem <b> &nbsp; {{ numberOfBookings }} reservas &nbsp; </b> sem
        intervenção, em que a data passou à mais de 5 dias.
        <Button
          label="Ver Reservas"
          class="p-button-raised p-button-plain p-button-text p-button-sm p-ml-2"
          @click="goBookings"
        />
      </span>
      <span
        class="p-d-flex p-ai-center p-mt-3"
        v-if="
          installationsSatisfactionForm > 0 && can('seeInstallationSurveys')
        "
      >
        Existem
        <b>
          &nbsp; {{ installationsSatisfactionForm }} Inquéritos de Satisfação
          das Instalações &nbsp;
        </b>
        por responder.
        <Button
          label="Ver Inquéritos"
          class="p-button-raised p-button-plain p-button-text p-button-sm p-ml-2"
          @click="goInstallationSatisfactionForm"
        />
      </span>
      <span
        class="p-d-flex p-ai-center p-mt-3"
        v-if="supportSatisfactionForm > 0 && can('seeAssistSurveys')"
      >
        Existem
        <b>
          &nbsp; {{ supportSatisfactionForm }} Inquéritos de Satisfação de
          Assistências &nbsp;
        </b>
        por responder.
        <Button
          label="Ver Inquéritos"
          class="p-button-raised p-button-plain p-button-text p-button-sm p-ml-2"
          @click="goSupportSatisfactionForm"
        />
      </span>
    </Message>
  </div>
</template>
<script>
import bookingService from "../../services/booking.service";
import satisfactionFormService from "../../services/satisfactionForm.service";
export default {
  name: "SupportAlerts",
  data() {
    return {
      numberOfBookings: 0,
      installationsSatisfactionForm: 0,
      supportSatisfactionForm: 0,
    };
  },
  async created() {
    if (this.can("seeBookinglistMenu")) {
      this.numberOfBookings =
        await bookingService.countSupportBookingWithoutIntervention(5);
    }
    if (this.can("seeInstallationSurveys")) {
      this.installationsSatisfactionForm =
        await satisfactionFormService.countInstallationsForResponding(
          new Date().getFullYear()
        );
    }
    if (this.can("seeAssistSurveys")) {
      this.supportSatisfactionForm =
        await satisfactionFormService.countSupportForResponding(
          new Date().getFullYear()
        );
    }
  },
  methods: {
    goBookings() {
      let route = this.$router.resolve({
        path: `/support/bookings`,
      });
      window.open(route.href, "_blank");
    },
    goInstallationSatisfactionForm() {
      let route = this.$router.resolve({
        path: `/satisfaction-form-installations`,
      });
      window.open(route.href, "_blank");
    },
    goSupportSatisfactionForm() {
      let route = this.$router.resolve({
        path: `/satisfaction-form-support`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
