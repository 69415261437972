var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
    (_vm.installationsSatisfactionForm > 0 && _vm.can('seeInstallationSurveys')) ||
    (_vm.installationsSatisfactionForm > 0 && _vm.can('seeInstallationSurveys')) ||
    (_vm.supportSatisfactionForm > 0 && _vm.can('seeAssistSurveys'))
  ),expression:"\n    (installationsSatisfactionForm > 0 && can('seeInstallationSurveys')) ||\n    (installationsSatisfactionForm > 0 && can('seeInstallationSurveys')) ||\n    (supportSatisfactionForm > 0 && can('seeAssistSurveys'))\n  "}]},[_c('Message',{attrs:{"severity":"warn"}},[(_vm.numberOfBookings > 0 && _vm.can('seeBookinglistMenu'))?_c('span',{staticClass:"p-d-flex p-ai-center"},[_vm._v(" Existem "),_c('b',[_vm._v("   "+_vm._s(_vm.numberOfBookings)+" reservas   ")]),_vm._v(" sem intervenção, em que a data passou à mais de 5 dias. "),_c('Button',{staticClass:"p-button-raised p-button-plain p-button-text p-button-sm p-ml-2",attrs:{"label":"Ver Reservas"},on:{"click":_vm.goBookings}})],1):_vm._e(),(
        _vm.installationsSatisfactionForm > 0 && _vm.can('seeInstallationSurveys')
      )?_c('span',{staticClass:"p-d-flex p-ai-center p-mt-3"},[_vm._v(" Existem "),_c('b',[_vm._v("   "+_vm._s(_vm.installationsSatisfactionForm)+" Inquéritos de Satisfação das Instalações   ")]),_vm._v(" por responder. "),_c('Button',{staticClass:"p-button-raised p-button-plain p-button-text p-button-sm p-ml-2",attrs:{"label":"Ver Inquéritos"},on:{"click":_vm.goInstallationSatisfactionForm}})],1):_vm._e(),(_vm.supportSatisfactionForm > 0 && _vm.can('seeAssistSurveys'))?_c('span',{staticClass:"p-d-flex p-ai-center p-mt-3"},[_vm._v(" Existem "),_c('b',[_vm._v("   "+_vm._s(_vm.supportSatisfactionForm)+" Inquéritos de Satisfação de Assistências   ")]),_vm._v(" por responder. "),_c('Button',{staticClass:"p-button-raised p-button-plain p-button-text p-button-sm p-ml-2",attrs:{"label":"Ver Inquéritos"},on:{"click":_vm.goSupportSatisfactionForm}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }